import { Header, Flex, Box, Image, Group, Burger } from "@mantine/core";
const HeaderView = ({
  classes,
  items,
  opened,
  toggle,
  colorValidation,
  images,
}) => {
  return (
    <Header
      height={85}
      sx={{
        borderBottom: `6px solid ${colorValidation}`,
        backgroundColor: "#F6F6F6",
      }}
    >
      <Flex
        mih={85}
        justify="space-between"
        align="center"
        direction="row"
        px={20}
      >
        <Box>
          <Image
            maw={150}
            mx="auto"
            src={images.logoAve}
            height={40}
            alt="Logo AVE"
            fit="contain"
          />
        </Box>
        <Box className={classes.links}>
          <Group position="center" noWrap>
            {items}
          </Group>
        </Box>
        <Burger
          className={classes.burger}
          opened={opened}
          onClick={toggle}
          sx={{ zIndex: 500 }}
          color={opened ? "#fff" : "#000"}
        />
      </Flex>
    </Header>
  );
};

export { HeaderView };
