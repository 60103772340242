import {
  images,
  useStyles,
  subscribeLib,
  ModalComponent,
  NotificationComponent,
} from "@mfe/js-common-ave-uiutils";
import {
  Menu,
  Center,
  Flex,
  LoadingOverlay,
  Overlay,
  Accordion,
  Text,
  Button,
  Anchor,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IoIosArrowDown } from "react-icons/io";
import { navigateToUrl } from "single-spa";
import { useState, useEffect } from "react";
import {
  useMsal,
  useIsAuthenticated,
  handleLogout,
  MsalProvider,
} from "@mfe/react-common-ave-msal";
import {
  errorApiStore,
  subscribe,
  getAccessTokenApi,
  fetchDisclaimerByUserData,
  fetchDisclaimerTextData,
  fetchUpdateDisclaimerByUser,
} from "@mfe/ts-common-ave-utilitaries";
import { HeaderView } from "../components/headerView.component";

const newLinks = (response, accounts) => {
  return {
    menu: [
      ...(response ?? []),
      {
        label: accounts[0]?.name,
        links: [
          {
            label: "Cerrar sesión",
            link: "/cerrar-sesion",
            links: [],
          },
        ],
      },
    ],
  };
};

export default function HeaderComponent({ organization, msalInstance }) {
  return (
    <MsalProvider instance={msalInstance}>
      <HeaderForm organization={organization} />
    </MsalProvider>
  );
}

export const HeaderForm = ({ organization }) => {
  const { classes, cx } = useStyles();
  const [selectedOption, setSelectedOption] = useState("");
  const [loading, setLoading] = useState(false);
  const [opened, { toggle, close }] = useDisclosure(false);
  const [tokenApi, setTokenApi] = useState("");
  const [openModalToken, { open: openModal, close: closeModal }] =
    useDisclosure(false);
  const [openedModal, { open, close: closeGenericModal }] =
    useDisclosure(false);
  const [modalContent, setModalContent] = useState({
    content: null,
    onClick: () => {},
    closeOnClickOutside: true,
    showButtons: true,
    onClose: () => closeGenericModal(),
  });
  const { instance, accounts } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const currentUrl = window.location.pathname;
  const userData = JSON.parse(localStorage.getItem("userData"));
  const [hasDisclaimer, setHasDisclaimer] = useState({
    disclaimerOpc: null,
    id: null,
  });
  const [data, setData] = useState(null);
  const [disclaimerText, setDisclaimerText] = useState(null);
  const [openedDisclaimer, { open: openDisclaimer, close: closeDisclaimer }] =
    useDisclosure(false);

  const [links, setLinks] = useState(null);
  const [showNotification, setShowNotification] = useState(
    errorApiStore.isError
  );
  const isAuth = localStorage.getItem("isAuth") === "true";

  const colorValidation = organization !== "cenit" ? "#cbd300" : "#14334D";

  useEffect(() => {
    subscribeLib("userData", (msg, data) => {
      setData(data);
      setLinks(newLinks(data.menu, accounts));
    });
    setSelectedOption(currentUrl);
  }, [currentUrl, accounts]);

  function handleClick(url) {
    navigateToUrl(url);
    if (url === "/viajes") {
      localStorage.removeItem("isEdit");
      localStorage.removeItem("liquidationID");
    }
    setSelectedOption(url);
    if (url === "/cerrar-sesion") {
      handleLogout(accounts[0].homeAccountId, setLoading, instance);
    }
  }

  useEffect(() => {
    if (currentUrl === "/" && isAuthenticated)
      getAccessTokenApi(instance, accounts, setTokenApi);
  }, [currentUrl, isAuthenticated]);

  useEffect(() => {
    if (
      !(
        currentUrl === "/historico" ||
        currentUrl === "/reserva-detalles" ||
        currentUrl === "/detalle-legalizacion"
      )
    ) {
      localStorage.removeItem("historicalFilters");
    }
  }, [currentUrl]);

  useEffect(() => {
    if (
      tokenApi &&
      isAuthenticated &&
      currentUrl === "/" &&
      userData?.datosUsuario?.codigoEmpleado?.length > 0
    ) {
      fetchDisclaimerByUserData(
        setHasDisclaimer,
        tokenApi,
        userData?.datosUsuario?.codigoEmpleado,
        setLoading
      );
    } else if (!isAuth && currentUrl !== "/" && data === null) {
      navigateToUrl("/");
      localStorage.clear();
    }
  }, [
    isAuthenticated,
    tokenApi,
    currentUrl,
    userData?.datosUsuario?.codigoEmpleado?.length,
    data,
    isAuth,
  ]);

  const handleHome = () => {
    const home = userData?.rules?.configuracionInicio?.home;
    if (home) navigateToUrl(userData?.rules?.configuracionInicio?.home);
    else {
      setModalContent({
        closeOnClickOutside: false,
        content: "No tiene configurado un menú o perfil.",
        onClick: () =>
          handleLogout(accounts[0].homeAccountId, setLoading, instance),
        showButtons: true,
        onClose: () => {
          closeGenericModal();
          handleLogout(accounts[0].homeAccountId, setLoading, instance);
        },
      });
      open();
    }
  };

  useEffect(() => {
    if (hasDisclaimer.disclaimerOpc) {
      fetchDisclaimerTextData(
        setDisclaimerText,
        tokenApi,
        setLoading,
        "DISCECP"
      );
      openDisclaimer();
    } else if (hasDisclaimer.disclaimerOpc === false) {
      handleHome();
    }
  }, [hasDisclaimer]);

  const handleAccept = async () => {
    try {
      setLoading(true);
      await fetchUpdateDisclaimerByUser(tokenApi, {
        id: hasDisclaimer.id,
        travelerId: userData?.datosUsuario?.codigoEmpleado,
        filial: userData?.datosUsuario?.filial,
      });
      closeDisclaimer();
      handleHome();
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const modalTimer = setInterval(() => {
      openModal();
    }, 3600000);

    return () => clearInterval(modalTimer);
  }, []);

  useEffect(() => {
    const unsubscribe = subscribe(errorApiStore, () => {
      setShowNotification(errorApiStore.isError);
    });
    return () => unsubscribe();
  }, []);

  const items = links?.menu?.map((link, index) => {
    const menuItems = link?.links?.map((item, index) => (
      <Menu.Item
        key={index}
        className={cx(classes.link, {
          [classes.linkActive]: selectedOption === item.link,
        })}
        onClick={(event) => {
          event.preventDefault();
          handleClick(item.link);
        }}
      >
        {item.label}
      </Menu.Item>
    ));

    if (link.links.length) {
      return (
        <Menu
          key={index}
          trigger="hover"
          transitionProps={{ exitDuration: 0 }}
          withinPortal
        >
          <Menu.Target>
            <a href={link.link} className={classes.link}>
              <Center>
                <span className={classes.linkLabel}>{link.label}</span>
                <IoIosArrowDown size="0.9rem" />
              </Center>
            </a>
          </Menu.Target>
          <Menu.Dropdown>{menuItems}</Menu.Dropdown>
        </Menu>
      );
    }

    return (
      <a
        key={index}
        href={link.link}
        className={cx(classes.link, {
          [classes.linkActive]: selectedOption === link.link,
        })}
        onClick={(event) => {
          event.preventDefault();
          handleClick(link.link);
        }}
      >
        {link.label}
      </a>
    );
  });

  const headerProps = {
    classes,
    items,
    opened,
    toggle,
    colorValidation,
    images,
  };

  if (loading) {
    return (
      <LoadingOverlay
        visible={loading}
        overlayBlur={50}
        loaderProps={{ color: "#cbd300" }}
      />
    );
  }

  return isAuthenticated ? (
    <>
      <NotificationComponent
        showNotification={showNotification}
        requestErrorMessage={errorApiStore.requestErrorMessage}
        buttonMessage={errorApiStore.buttonMessage}
      />
      <ModalComponent
        opened={openedDisclaimer}
        onClose={closeDisclaimer}
        title="Términos y Condiciones"
        closeOnClickOutside={false}
      >
        <div
          dangerouslySetInnerHTML={{
            __html: disclaimerText?.[0]?.descripcion,
          }}
        />
        <Flex gap={16}>
          <Button
            className={classes.buttonOutline}
            fullWidth
            onClick={() =>
              handleLogout(accounts[0].homeAccountId, setLoading, instance)
            }
          >
            Cancelar
          </Button>
          <Button className={classes.button} fullWidth onClick={handleAccept}>
            Aceptar
          </Button>
        </Flex>
      </ModalComponent>
      <ModalComponent
        opened={openModalToken}
        onClose={() => {
          closeModal();
          location.reload();
        }}
        title="Alerta"
      >
        <Text fw={500} color="#004236" ta="center">
          ¿Desea continuar en la sesión?
        </Text>
        <Flex align="center" gap={12} mt={16}>
          <Button
            fullWidth
            className={classes.buttonOutline}
            onClick={() =>
              handleLogout(accounts[0].homeAccountId, setLoading, instance)
            }
          >
            Cerrar sesión
          </Button>
          <Button
            fullWidth
            className={classes.button}
            onClick={() => location.reload()}
          >
            Continuar
          </Button>
        </Flex>
      </ModalComponent>
      <ModalComponent
        opened={openedModal}
        onClose={modalContent.onClose}
        title="Alerta"
        closeOnClickOutside={modalContent.closeOnClickOutside}
      >
        {typeof modalContent.content === "string" ? (
          <Text ta="center" fw={700} color="#004236">
            {modalContent.content}
          </Text>
        ) : (
          modalContent.content
        )}
        {modalContent.showButtons && (
          <Flex gap={16} my={12}>
            <Button
              onClick={modalContent.onClick}
              fullWidth
              className={classes.button}
            >
              Aceptar
            </Button>
          </Flex>
        )}
      </ModalComponent>
      <HeaderView {...headerProps} />
      {opened && (
        <Overlay color={colorValidation} opacity={1} zIndex={400}>
          <Accordion mt={90}>
            {links?.menu?.map((link, index) => {
              return link.links.length ? (
                <Accordion.Item
                  key={index}
                  value={link.label}
                  sx={{
                    "button:active": { backgroundColor: colorValidation },
                    borderColor: "#fff",
                  }}
                >
                  <Accordion.Control sx={{ color: "#fff" }} h={62}>
                    <Text
                      c="#fff"
                      fw={700}
                      fz="sm"
                      pl={12}
                      className={classes.textShadow}
                    >
                      {link.label}
                    </Text>
                  </Accordion.Control>
                  <Accordion.Panel ml={10}>
                    {link?.links?.map((item, index) => (
                      <Anchor
                        key={index}
                        className={(classes.link, classes.textShadow)}
                        fw={500}
                        color="#fff"
                        display="block"
                        onClick={(e) => {
                          e.preventDefault();
                          handleClick(item.link);
                          close();
                        }}
                      >
                        {item.label}
                      </Anchor>
                    ))}
                  </Accordion.Panel>
                </Accordion.Item>
              ) : (
                <Text
                  key={index}
                  px={20}
                  h={62}
                  sx={{
                    borderBottom: "1px solid #fff",
                    display: "flex",
                    alignItems: "center",
                  }}
                  className={classes.textShadow}
                >
                  <Anchor
                    href={link.link}
                    className={classes.link}
                    style={{
                      color: "#fff",
                    }}
                    onClick={(e) => {
                      e.preventDefault();
                      handleClick(link.link);
                      close();
                    }}
                  >
                    {link.label}
                  </Anchor>
                </Text>
              );
            })}
          </Accordion>
        </Overlay>
      )}
    </>
  ) : null;
};
